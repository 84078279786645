import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	PetitionPagePetitionInfo,
	type PetitionPagePetitionInfoQuery,
	type PetitionPagePetitionInfoQueryVariables,
} from './petition.graphql';

export type PartialPetition = NonNullable<PetitionPagePetitionInfoQuery['petition']>;

export async function getPetitionInfo(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition | undefined> {
	const { data } = await fetch<PetitionPagePetitionInfoQuery, PetitionPagePetitionInfoQueryVariables>({
		query: PetitionPagePetitionInfo,
		variables: { slugOrId },
		rejectOnError: true,
	});
	return data?.petition || undefined;
}
