import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { FCM_NEW_PETITION_PAGE_ACCESS_EXPERIMENT } from '@change-corgi/fcm/configs';

export const getNewPetitionPage = async (utilityContext: UtilityContext): Promise<boolean> => {
	const fcm = await utilityContext.fcm.get({
		newPetitionPageAccess: FCM_NEW_PETITION_PAGE_ACCESS_EXPERIMENT,
	});
	if (!fcm.newPetitionPageAccess) {
		return false;
	}

	const { enabled, experimentName, mobileExperimentName, desktopExperimentName } = fcm.newPetitionPageAccess;
	if (!enabled) {
		return false;
	}

	if (!(mobileExperimentName && mobileExperimentName)) {
		return checkGammaExperiment(experimentName, utilityContext);
	}

	// Note that we're only targeting 'mobile' and not 'tablet' because what we really want to target are small
	// viewports. UserAgent is a best-guess before we render the app. It's still possible for a user on a tablet/desktop
	// to have a small screen/window and see the 'mobile' experience, or for a device in the 'mobile' experiment to be
	// large enough to see the desktop layout.
	const isMobile = utilityContext.userAgent.getParsedUserAgent().getDevice().type === 'mobile';

	if (isMobile) {
		return checkGammaExperiment(mobileExperimentName, utilityContext);
	}
	return checkGammaExperiment(desktopExperimentName, utilityContext);
};

const checkGammaExperiment = async (
	experimentName: string | undefined,
	utilityContext: UtilityContext,
): Promise<boolean> => {
	if (!experimentName) {
		return false;
	}

	const experiment = utilityContext.experiments.get(experimentName);
	await experiment.treat();

	return experiment.variation === 'gamma';
};
